.center-align {
    align-self: center
}

.memory-verse {
    max-width: 600px;
    text-align: center;
    font-style: italic;
    color: #222;
    font-size: 16px;
    font-weight: lighter;
    font-family: "PT Serif", serif !important;
    line-height: 36px;
}

.memory-verse-passage {
    display: block;
    text-align: center;
    color: #222;
    font-size: 16px;
    font-family: "PT Serif", serif !important;
}

.full-passage {
    font-size: 18px;
    color: #222;
    line-height: 30px;
    margin-right: 1rem;
}

.full-text {
    text-align: center;
    max-width: 600px;
}